<template>
  <div
    v-if="showPlaceholder"
    class="business-profile-placeholder"
  >
    <div class="business-profile-placeholder__title">
      <span>
        You don't have a business profile yet.
      </span>
    </div>
    <div class="business-profile-placeholder__image">
      <img
        :src="placeholderBp"
        alt="Placeholder business profile"
      >
    </div>
    <div class="business-profile-placeholder__button">
      <b-button
        variant="primary"
        @click="$router.push('/profile_company_manage')"
      >
        Create business profile
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import placeholderBp from '@/assets/images/pages/placeholder-bp.svg'
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      placeholderBp,

      showPlaceholder: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  created() {
    /**
     * Get company profile
     */
    this.$router.push(`profile_company/${this.user.active_business_profile.slug}`)
  },
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/abstracts/_responsive.scss";

  .business-profile-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      padding-bottom: 25px;
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }

    &__image {
      img {
        height: 300px;
        @include media-q-md {
          height: 200px;
        }
        @include media-q-xs {
          height: 150px;
        }
      }
    }

    &__button {
      padding-top: 25px;
    }
  }
</style>
